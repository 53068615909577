<template>
  <b-form @submit.prevent="saveSettings">
    <b-card
      title="App Settings"
    >
      <b-row>
        <b-col md="4">
          <b-form-group
            label="App Name"
            label-for="mc-app-name"
          >
            <b-form-input
              id="mc-app-name"
              v-model="details.app_name"
              placeholder="App Name"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Email"
            label-for="mc-email"
          >
            <b-form-input
              id="mc-email"
              v-model="details.email"
              placeholder="Email"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Phone Number"
            label-for="mc-phone-number"
          >
            <b-form-input
              id="mc-phone-number"
              v-model="details.phone_number"
              placeholder="Phone Number"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Bank Transfer Limit"
            label-for="mc-transfer-charge"
          >
            <b-form-input
              id="mc-transfer-charge"
              v-model="details.transfer_limit"
              placeholder="Bank Transfer Limit"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Withdrawal Limit"
            label-for="mc-withdrawal-limit"
          >
            <b-form-input
              id="mc-withdrawal-limit"
              v-model="details.withdrawal_limit"
              placeholder="Withdrawal Limit"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Transaction Limit"
            label-for="mc-transaction-limit" 
          >
            <b-form-input
              id="mc-transaction-limit"
              v-model="details.transaction_limit"
              placeholder="Transaction Limit"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="EPIN Commission"
            label-for="mc-pin-commission" 
          >
            <b-form-input
              id="mc-pin-commission"
              v-model="details.epin_commission"
              placeholder="EPIN Commission"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="PIN Transfer Commission"
            label-for="mc-pin-commission" 
          >
            <b-form-input
              id="mc-pin-commission"
              v-model="details.epin_transfer_commission"
              placeholder="PIN Transfer Commission"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Bank Transfer Commission"
            label-for="mc-transfer-commission" 
          >
            <b-form-input
              id="mc-transfer-commission"
              v-model="details.transfer_commission"
              placeholder="Bank Transfer Commission"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Referral Bonus"
            label-for="mc-referral-bonus" 
          >
            <b-form-input
              id="mc-referral-bonus"
              v-model="details.referral_bonus"
              placeholder="Referral Bonus"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Safelock Breaking Fee"
            label-for="mc-safelock-breaking-fee" 
          >
            <b-form-input
              id="mc-safelock-breaking-fee"
              v-model="details.safelock_breaking_fee"
              placeholder="Safelock Breaking Fee"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Flex Savings Breaking Fee"
            label-for="mc-flex-breaking-fee" 
          >
            <b-form-input
              id="mc-flex-breaking-fee"
              v-model="details.flex_breaking_fee"
              placeholder="Flex Breaking Fee"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Account Inactivity Message"
            label-for="mc-deactivation_message" 
          >
            <ckeditor :editor="editor" v-model="details.deactivation_message" :config="editorConfig"></ckeditor>
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col md="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Save
            <b-spinner
              v-show="loading"
              variant="light"
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Safelock Interest Rate Settings"
    >
      <b-row>
        <b-col md="4">
          <b-form-group
            label="Safelock Mini (3 Months) %"
            label-for="mc-mini"
          >
            <b-form-input
              id="mc-mini"
              v-model="details.safelock_mini"
              placeholder="0.00"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Safelock Midi (6 Months) %"
            label-for="mc-midi"
          >
            <b-form-input
              id="mc-midi"
              v-model="details.safelock_midi"
              placeholder="0.00"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Safelock Maxi (9 Months) %"
            label-for="mc-maxi"
          >
            <b-form-input
              id="mc-maxi"
              v-model="details.safelock_maxi"
              placeholder="0.00"
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Save
            <b-spinner
              v-show="loading"
              variant="light"
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Flex Savings Interest Rate Settings"
    >
      <b-row>
        <b-col md="4">
          <b-form-group
            label="Flex Mini (0 - 5,000) %"
            label-for="flex-mini"
          >
            <b-form-input
              id="flex-mini"
              v-model="details.flex_mini"
              placeholder="0.00"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Flex Midi (5,000 - 50,000) %"
            label-for="flex-midi"
          >
            <b-form-input
              id="flex-midi"
              v-model="details.flex_midi"
              placeholder="0.00"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Flex Maxi (50,000 and above) %"
            label-for="flex-maxi"
          >
            <b-form-input
              id="flex-maxi"
              v-model="details.flex_maxi"
              placeholder="0.00"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Flex Duration (366 days maximum)"
            label-for="flex-duration"
          >
            <b-form-input
              id="flex-duration"
              v-model="details.flex_duration"
              placeholder="Duration of flex savings"
              type="number"
              :min="1"
              :max="366"
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Save
            <b-spinner
              v-show="loading"
              variant="light"
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Bank Transfer Charges"
    >
      <b-row>
        <b-col md="4">
          <b-form-group
            label="Transfer Charge Mini"
            label-for="mc-mini"
          >
            <b-form-input
              v-model="details.transfer_charge_mini"
              placeholder="0.00"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Transfer Charge Midi"
            label-for="mc-mini"
          >
            <b-form-input
              v-model="details.transfer_charge_midi"
              placeholder="0.00"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Transfer Charge Max"
            label-for="mc-mini"
          >
            <b-form-input
              v-model="details.transfer_charge_max"
              placeholder="0.00"
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Save
            <b-spinner
              v-show="loading"
              variant="light"
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Monnify Settings"
    >
      <b-row>
        <b-col md="6">
          <b-form-group
            label="API Key"
            label-for="mc-api-key"
          >
            <b-form-input
              id="mc-api-key"
              v-model="details.monnify_apikey"
              placeholder="API KEY"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Contract Code"
            label-for="mc-contract-code"
          >
            <b-form-input
              id="mc-contract-code"
              v-model="details.monnify_contract"
              placeholder="Contract Code"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Secret Code"
            label-for="mc-secret-code"
          >
            <b-form-input
              id="mc-secret-code"
              v-model="details.monnify_secret"
              placeholder="Secret Code"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Wallet ID (for disbursment)"
            label-for="mc-wallet-id"
          >
            <b-form-input
              id="mc-wallet-id"
              v-model="details.monnify_walletId"
              placeholder="Wallet ID"
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Save
            <b-spinner
              v-show="loading"
              variant="light"
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Flutterwave Settings"
    >
      <b-row>
        <b-col md="6">
          <b-form-group
            label="API Key"
            label-for="mc-api-key"
          >
            <b-form-input
              id="mc-api-key"
              v-model="details.flutterwave_apikey"
              placeholder="API KEY"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Secret Key"
            label-for="mc-secret-code"
          >
            <b-form-input
              id="mc-secret-code"
              v-model="details.flutterwave_secret"
              placeholder="Secret Code"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Transfer Charge Mini %"
            label-for="mc-flwv-id"
          >
            <b-form-input
              id="mc-flwv-id"
              v-model="details.flwv_trans_charge_mini"
              placeholder="flwv Mini"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Transfer Charge Midi %"
            label-for="mc-flwv-id"
          >
            <b-form-input
              v-model="details.flwv_trans_charge_midi"
              placeholder="flwv Midi"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Transfer Charge Max %"
            label-for="mc-flwv-id"
          >
            <b-form-input
              id="mc-flwv-id"
              v-model="details.flwv_trans_charge_max"
              placeholder="flwv Max"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Card Transaction Charge %"
            label-for="mc-flwv-id"
          >
            <b-form-input
              id="mc-flwv-id"
              v-model="details.flwv_card_trans_charge"
              placeholder="Card Transaction Charge"
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Save
            <b-spinner
              v-show="loading"
              variant="light"
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Capricorn Settings"
    >
      <b-row>
        <b-col md="6">
          <b-form-group
            label="API Key"
            label-for="mc-api-key"
          >
            <b-form-input
              id="mc-api-key"
              v-model="details.baxi_apikey"
              placeholder="API KEY"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Username"
            label-for="mc-username"
          >
            <b-form-input
              id="mc-username"
              v-model="details.baxi_username"
              placeholder="Username"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Secret Code"
            label-for="mc-secret-code"
          >
            <b-form-input
              id="mc-secret-code"
              v-model="details.baxi_secret"
              placeholder="Secret Code"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Agent ID"
            label-for="mc-agent-id"
          >
            <b-form-input
              id="mc-agent-id"
              v-model="details.baxi_agent_id"
              placeholder="Agent ID"
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Save
            <b-spinner
              v-show="loading"
              variant="light"
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Features"
    >
      <b-row>
        <b-col md="6">
          <b-form-group
            label-for="mc-bills"
          >
          <b-form-checkbox-group v-model="details.features">
            <b-form-checkbox value="bills_payment">Bills Payment</b-form-checkbox> <br>
            <b-form-checkbox value="faaspay">FaasPay</b-form-checkbox><br>
            <b-form-checkbox value="epin">Epin</b-form-checkbox><br>
            <b-form-checkbox value="safelock">Safelock</b-form-checkbox><br>
            <b-form-checkbox value="flex_savings">Flex Savings</b-form-checkbox><br>
            <b-form-checkbox value="withdrawal">Withdrawal</b-form-checkbox><br>
            <b-form-checkbox value="wallet_transfer">Wallet Transfer</b-form-checkbox><br>
            <b-form-checkbox value="wallet_topup">Wallet Topup</b-form-checkbox><br>
            <b-form-checkbox value="bank_transfer">Bank Transfer</b-form-checkbox><br>
            <b-form-checkbox value="commission">Commission</b-form-checkbox><br>
            <b-form-checkbox value="support">Support</b-form-checkbox><br>
            <b-form-checkbox value="referral">Referral</b-form-checkbox>
            <b-form-checkbox value="reports">Report</b-form-checkbox>
          </b-form-checkbox-group>
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Save
            <b-spinner
              v-show="loading"
              variant="light"
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-form>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BSpinner, BFormTextarea, BFormCheckboxGroup, BFormCheckbox
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  components: {
    ClassicEditor,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BSpinner,
    BFormTextarea,
    BFormCheckboxGroup,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        modules: {
          toolbar: '#toolbar',
        },
      },       
      settings: {},
      details: {
        transfer_limit: '0.00',
        withdrawal_limit: '0.00',
        epin_commission: '0.00',
        transfer_commission: '0.00',
        monnify_apikey: '',
        monnify_secret: '',
        monnify_contract: '',
        monnify_walletId: '',
        baxi_apikey: '',
        baxi_secret: '',
        baxi_username: '',
        baxi_agent_Id: '',
        email: '',
        phone_number: '',
        safelock_mode: 1,
        flwv_card_trans_charge: 0,
        flwv_trans_charge_mini: 0,
        flwv_trans_charge_midi: 0,
        flwv_trans_charge_max: 0,
        transfer_charge_mini: 0,
        transfer_charge_midi: 0,
        transfer_charge_max: 0,
        deactivation_message: '',
        epin_transfer_commission: 0.00,
        flutterwave_apikey: '',
        flutterwave_secret: '',
        features: [],

      },
      loading: false,
      selectedOptions: []
    }
  },
  created() {
    this.getSettings()
    let data = localStorage.getItem('userData')
    let user = JSON.parse(data)
    this.user = user
    if(user.role && user.role.toLowerCase() != 'admin') {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('userData')
      window.location.reload()
    }    
  },
  methods: {
    getSettings() {
      this.$http.get(`${this.$url}/settings`)
        .then(response => {
          if (response.status) {
            this.settings = response.data.data.app_setting
            this.details.transfer_limit = this.settings.transfer_limit
            this.details.transaction_limit = this.settings.transaction_limit
            this.details.withdrawal_limit = this.settings.withdrawal_limit
            this.details.epin_commission = this.settings.epin_commission
            this.details.epin_transfer_commission = this.settings.epin_transfer_commission
            this.details.transfer_commission = this.settings.transfer_commission
            this.details.deactivation_message = this.settings.deactivation_message

            this.details.monnify_apikey = this.settings.monnify_apikey
            this.details.monnify_secret = this.settings.monnify_secret
            this.details.monnify_contract = this.settings.monnify_contract
            this.details.monnify_walletId = this.settings.monnify_walletId

            this.details.flutterwave_apikey = this.settings.flutterwave_apikey
            this.details.flutterwave_secret = this.settings.flutterwave_secret

            this.details.baxi_apikey = this.settings.baxi_apikey
            this.details.baxi_secret = this.settings.baxi_secret
            this.details.baxi_username = this.settings.baxi_username
            this.details.baxi_agent_id = this.settings.baxi_agent_id
            this.details.app_name = this.settings.app_name
            this.details.referral_bonus = this.settings.referral_bonus
            this.details.safelock_interest_rate = this.settings.safelock_interest_rate
            this.details.email = this.settings.email
            this.details.phone_number = this.settings.phone_number

            this.details.safelock_mode = this.settings.safelock_mode
            this.details.safelock_mini = this.settings.safelock_mini
            this.details.safelock_midi = this.settings.safelock_midi
            this.details.safelock_maxi = this.settings.safelock_maxi


            this.details.safelock_breaking_fee = this.settings.safelock_breaking_fee
            this.details.flex_breaking_fee = this.settings.flex_breaking_fee

            this.details.flex_mini = this.settings.flex_mini
            this.details.flex_midi = this.settings.flex_midi
            this.details.flex_maxi = this.settings.flex_maxi
            this.details.flex_duration = this.settings.flex_duration

            this.details.transfer_charge_mini = this.settings.transfer_charge_mini
            this.details.transfer_charge_midi = this.settings.transfer_charge_midi
            this.details.transfer_charge_max = this.settings.transfer_charge_max

            this.details.flwv_trans_charge_mini = this.settings.flwv_trans_charge_mini
            this.details.flwv_trans_charge_midi = this.settings.flwv_trans_charge_midi
            this.details.flwv_trans_charge_max = this.settings.flwv_trans_charge_max
            this.details.flwv_card_trans_charge = this.settings.flwv_card_trans_charge

            this.details.features = JSON.parse(this.settings.features)
            this.selectedOptions = JSON.parse(this.settings.features)
          }
        })
    },
    saveSettings() {
      this.loading = true
      this.details.features = JSON.stringify(this.details.features)
      this.$http.post(`${this.$url}/settings`, this.details)
        .then(response => {
          if (response.data.status) {
            window.location.reload()
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectOption(e) {
      // console.log(e)
      const selected = e
      this.details.features = JSON.stringify(selected)
      // selected.map((item, index) => {
      //   console.log(item)
      //   this.details[item] = true
      //   console.log(this.details)
      // })
        console.log(this.selectedOptions)
    }
  },
}
</script>
